import { FC, useCallback, useState, MouseEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";

import Button from "@ingka/button";
import Text from "@ingka/text";
import CalendarIcon from "@ingka/ssr-icon/paths/calendar";
import { useBoolean } from "@pdpp/lib-react";

import { css } from "../__generated-styled-system/css";
import { View } from "../types/client";
import { PlanView } from "../types/api";
import { period$, periodString } from "../features/period";
import { DatePickerRange } from "../components/DatePickerRange";
import { view$ } from "../features/plan/state";
import { orgId$ } from "../features/org/state";
import { useGetMethods } from "../features/plan/usePlanMethods";
import { getHeadcountRequest } from "../features/plan/api";

const periodWrapperStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: "space50",
});

const periodTextStyles = css({
  marginLeft: "space100!",
});

export const Period: FC = () => {
  const [t, i18next] = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const signalPeriod = period$.value;

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [value, setValue] = useState<Readonly<[Date, Date]>>(signalPeriod);

  const [isPopoverShown, , hidePopover, togglePopover] = useBoolean(false);

  useEffect(() => {
    setValue(signalPeriod);
  }, [signalPeriod]);

  const handleDatePickerChange = useCallback((start: Date, end: Date) => {
    setValue([start, end]);
  }, []);

  const methods = useGetMethods({
    orgNodeId: orgId$.value ?? "",
  });

  const handleApply = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    hidePopover();
    const [startDate, endDate] = value ?? [];

    const newPeriod: Readonly<[Date, Date]> = [startDate, endDate];
    period$.value = [...newPeriod];

    view$.value = View.Day;

    const params = new URLSearchParams(window.location.search);
    getHeadcountRequest({
      view: View.Week,
      orgNodeId: orgId$.value ?? "",
      period: [
        format(period$.value[0], "yyyy-MM-dd"),
        format(period$.value[1], "yyyy-MM-dd"),
      ],
    });
    params.set(
      "period",
      newPeriod.map((v) => v.toLocaleString("sv").slice(0, 10)).toString(),
    );
    params.set("view", PlanView.Day.toString());
    setSearchParams(params.toString());
    methods.refetch();
  };

  return (
    <div className={periodWrapperStyles}>
      <Text tagName="span" bodySize="s" className={periodTextStyles}>
        {t("labels.period")}
      </Text>
      <Button
        ref={setAnchorEl}
        small={true}
        type="tertiary"
        text={periodString(
          period$.value[0],
          period$.value[1],
          i18next.language,
        )}
        iconPosition="leading"
        ssrIcon={CalendarIcon}
        onClick={togglePopover}
        data-analytics="plan/period/select-date"
      />
      {isPopoverShown && (
        <DatePickerRange
          selectedRange={value}
          anchorEl={anchorEl}
          onChange={handleDatePickerChange}
          locale={i18next.language}
          verticalAlignment="top"
          horizontalAlignment="right"
          horizontalPosition="right"
          verticalPosition="bottom"
          onApply={handleApply}
          clickAwayHandler={hidePopover}
        />
      )}
    </div>
  );
};
