import { signal, effect, computed } from "@preact-signals/safe-react";

import { getFiscalYear } from "../../utils";
import { decodePlanningPeriod, encodePlanningPeriod } from "./transforms";
import {
  decodeShareableSetting,
  encodeShareableSetting,
} from "../../utils/shareableSetting";
import { HeadCount, PlanViewData } from "../../types/api";
import { getDurationFromHeadCountFormatted } from "../plan/transforms";

const KEY = "period";

type PlanData = PlanViewData["data"];

export const periodPlan$ = signal<PlanData>([]);

export const modifiedPlans$ = signal<Map<string, number[]>>(new Map());

export const period$ = signal(
  decodeShareableSetting(KEY, decodePlanningPeriod) ?? getFiscalYear(),
);

type PeriodPlanById = Map<string, HeadCount>;

export const PERIOD_PLAN_BY_ID: PeriodPlanById = new Map();

effect(() => {
  const value = period$.value;
  if (value !== undefined) {
    encodeShareableSetting(KEY, value, encodePlanningPeriod);
  }
});

export const periodPlanTotal$ = computed<string>(() => {
  const periodPlan = periodPlan$.value;

  return getDurationFromHeadCountFormatted(
    periodPlan.flatMap((h) => h.dayQuarters),
  );
});

// Populates period plan Map
effect(() => {
  const periodPlan = periodPlan$.value;

  for (const node of periodPlan) {
    PERIOD_PLAN_BY_ID.set(node.id, node);
  }
});
