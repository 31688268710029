import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import SSRIcon from "@ingka/ssr-icon";
import DocumentPencilIcon from "@ingka/ssr-icon/paths/document-pencil";
import Text from "@ingka/text";

import { css } from "../__generated-styled-system/css";
import { period$, periodPlanTotal$ } from "../features/period";
import { orgId$ } from "../features/org/state";
import { getHeadcountRequest } from "../features/plan/api";
import { View } from "../types/client";

const wrapperStyles = css({
  display: "grid",
  gridTemplateColumns: "min-content 1fr",
  gridTemplateRows: "min-content 1fr",
  gridRowGap: "space100",
});

const periodPlanTextStyles = css({
  marginLeft: "space50!",
});

const textStyles = css({ gridColumn: "-1 / 1" });

export const PlanPeriod: FC = () => {
  const { t } = useTranslation();

  const orgId = orgId$.value;

  useEffect(() => {
    getHeadcountRequest({
      view: View.Week,
      orgNodeId: orgId ?? "",
      period: [
        format(period$.value[0], "yyyy-MM-dd"),
        format(period$.value[1], "yyyy-MM-dd"),
      ],
    });
  }, [orgId]);

  return (
    <div className={wrapperStyles}>
      <Text className={textStyles} tagName="p" bodySize="s">
        {t("plan.periodPlan")}
      </Text>
      <SSRIcon paths={DocumentPencilIcon} />
      <Text className={periodPlanTextStyles} tagName="span" headingSize="xs">
        {periodPlanTotal$}
      </Text>
    </div>
  );
};
