import { Authentication } from "@pdpp/lib-react";

import { VITE_APP_CLIENT_ID, VITE_APP_TENANT_ID } from "../utils/runtimeConfig";

export class Auth {
  private static instance: Authentication | undefined = undefined;

  private constructor() {}

  static get(clientId: string | undefined = undefined): Authentication {
    // When running app as standalone: Initialize the instance if it's not already initialized
    // using values from the runtime config
    // When running app as parcel: Initialize the instance with
    // the clientId passed as an argument
    if (this.instance === undefined || clientId !== undefined) {
      this.instance = new Authentication({
        clientId: clientId !== undefined ? clientId : VITE_APP_CLIENT_ID,
        tenantId: VITE_APP_TENANT_ID,
      });
    }
    return this.instance;
  }
}
